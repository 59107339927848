import { Component, ViewChild, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { AuthService } from 'src/app/services/auth.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/utils/common.service';
import { Title } from '@angular/platform-browser';
import { EmpresaService } from 'src/app/services/empresa.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Router } from '@angular/router';
import { RolService } from 'src/app/services/rol.service';
import { Rol } from 'src/app/models/rol.model';
import { INavData } from '@coreui/angular';
import { RolAcceso } from 'src/app/models/rol-acceso.model';
import { INavDataExt } from 'src/app/utils/INavDataExt';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  @ViewChild('myModal', null) public myModal: ModalDirective;

  public sidebarMinimized = false;
  public navItems = [];
  public nombre = '';
  public rolNombre = '';
  public coinciden = true;
  public isValid = false;
  public logoString;

  constructor(
    private authService: AuthService,
    private util: CommonService,
    private titleService: Title,
    private empresaService: EmpresaService,
    private hotkeysService: HotkeysService,
    private rolService: RolService,
    private route: Router,
  ) {
    this.nombre = this.authService.getNombre();
    this.hotkeysService.add(new Hotkey('alt+q', (event: KeyboardEvent): boolean => {
      this.route.navigate(['administracion', 'documentos', 'new']);
      return false; // Prevent bubbling
    }));
    let rol: Rol = this.rolService.getRolFromLS();
    let newNavItems: INavDataExt[] = [];
    let groupCount: { group: string, count: number }[] = [];
    let rolAcessos = [];
    if (rol) {
      this.rolNombre = rol.nombre;
      rolAcessos = rol.rolAccesos;
    }
    navItems.forEach((nav => {
      if (rolAcessos) {
        let rolAcceso = rolAcessos.filter((acceso: RolAcceso) => {
          return acceso.modulo == nav.code;
        });
        if (rolAcceso.length == 0) {
          newNavItems.push(nav);
        } else if (rolAcceso[0].nivel > 0) {
          newNavItems.push(nav);
        }
      }


      if (nav.group) {
        let gc = groupCount.filter((gc) => {
          return gc.group == nav.group;
        });
        if (gc.length > 0) {
          let count = gc[0].count + 1;
          groupCount.map(gcMap => {
            if (gcMap.group == nav.group)
              gcMap.count = count;
            return gcMap;
          });
        } else {
          groupCount.push({ group: nav.group, count: 1 })
        }
      }
    }));


    let finalNavItems: INavDataExt[] = [];

    newNavItems.forEach((navItem) => {
      if (navItem.title) {
        finalNavItems.push(navItem);
        let gc = groupCount.filter((gc) => {
          return gc.group == navItem.name
        });
        if (gc.length > 0) {
          if (gc[0].count < navItem.childrenNumber) {
            finalNavItems.push(navItem);
          }
        } else {
          finalNavItems.push(navItem);
        }
      } else {
        finalNavItems.push(navItem);
      }
    });

    this.navItems = finalNavItems;

    this.empresaService.getCurrent().subscribe((empresa) => {
      (empresa) ? this.titleService.setTitle(empresa.razonSocial) : this.titleService.setTitle('Bienvenido');

      let logo = Buffer.from(empresa.logotipo);

      let src = 'data:image/png;base64,' + btoa(
        logo.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      this.logoString = { src: src, width: 89, height: 25, alt: 'CoreUI Logo' };
    },
      (error) => {
        this.titleService.setTitle('Bienvenido');
      });

  }

  ngOnInit() {

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authService.logout();
  }

  cambiarContrasena() {
    this.util.showLoader();
    let passActual = (<HTMLInputElement>document.getElementById('pass-actual')).value;
    let passNueva = (<HTMLInputElement>document.getElementById('pass-nueva')).value;

    this.revisarCoincidencia();

    if (this.coinciden) {
      this.authService.changePass({
        passActual,
        passNueva
      }).subscribe(data => {
        if (data) {
          alert('La contraseña se cambió correctamente. Por favor, vuelva a iniciar sesión');
          this.myModal.hide();
          this.authService.logout();
        }
        this.util.hideLoader();
      }, error => {
        let message = '';
        switch (error.status) {
          case 404:
            message = 'Contraseña actual errónea.'
            break;
          case 401:
            message = 'No tienes permisos para hacer esto.'
            break;
          default:
            message = 'Ocurrió un error. ' + error.message;
            break;
        }
        alert(message);
        this.util.hideLoader();
      });
    }
    else {
      alert('Las contraseñas no coinciden.')
      this.util.hideLoader();
    }
  }

  revisarCoincidencia() {
    let passActual = (<HTMLInputElement>document.getElementById('pass-actual')).value;
    let passNueva = (<HTMLInputElement>document.getElementById('pass-nueva')).value;
    let passRepite = (<HTMLInputElement>document.getElementById('pass-repite')).value;
    this.coinciden = ((passNueva === passRepite) && (passNueva !== ''));

    this.isValid = ((passActual !== '') && this.coinciden);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
