import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './services/auth.guard';
import { RegisterComponent } from './components/register/register.component';
const ɵ0 = {
    title: 'Login'
}, ɵ1 = {
    title: 'Registro'
}, ɵ2 = {
    title: 'Inicio'
}, ɵ3 = () => import("./components/views/inventario/inventario.module.ngfactory").then(m => m.InventarioModuleNgFactory), ɵ4 = () => import("./components/views/administracion/administracion.module.ngfactory").then(m => m.AdministracionModuleNgFactory), ɵ5 = () => import("./components/views/empresa/empresa.module.ngfactory").then(m => m.EmpresaModuleNgFactory), ɵ6 = () => import("./components/views/dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory);
const routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: ɵ1
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: DefaultLayoutComponent,
        data: ɵ2,
        children: [
            {
                path: 'inventario',
                loadChildren: ɵ3
            },
            {
                path: 'administracion',
                loadChildren: ɵ4
            },
            {
                path: 'empresa',
                loadChildren: ɵ5
            },
            {
                path: 'dashboard',
                loadChildren: ɵ6
            }
        ]
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
