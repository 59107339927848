import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';


const AUTH_HEADER: string = 'Authorization';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;

  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error) {
          if (error.status === 0) {
            alert('El servidor no esta disponible.');
          }
          if (error.status === 401) {
            this.authService.logout();
            // return this.handleError401(request, next);
          }
        }
        return throwError(error);
      })
    );
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(AUTH_HEADER, `Bearer ${token}`)
    });
  }

  private handleError401(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(jwt => {
        return next.handle(this.addToken(request, jwt));
      })
    );
  }
}
