import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.verifyLocalStorage();
    if (this.authService.isAuth()) {
      return true;
    }
    console.log('Not authenticated, redirecting to login');
    this.router.navigate(['login']);
    return false;
  }
}
