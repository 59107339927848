import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Auth } from '../models/auth.model';
import { JwtService } from './jwt.service';
import { CurrentUser } from '../models/current-user.model';
import { environment } from 'src/environments/environment';
import { CommonService } from '../utils/common.service';
import { RolService } from './rol.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class AuthService {
  private readonly REQUEST_ROUTE = environment.apiUrl + '/users';

  private _currentUser = new BehaviorSubject<CurrentUser>(null);

  private _isAuthenticated = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router,
    private util: CommonService,
    private lsService: LocalStorageService,
    private rolService: RolService
  ) {}

  isAuth() {
    return this._isAuthenticated.getValue();
  }

  get isAuthenticated() {
    return this._isAuthenticated.asObservable();
  }

  getId(): string {
    return this._currentUser.getValue().id;
  }

  getIdRol(): number {
    return this._currentUser.getValue().idRol;
  }

  getDni(): string {
    return this._currentUser.getValue().dni;
  }

  getNombre(): string {
    return this._currentUser.getValue().nombre;
  }

  get currentUser() {
    return this._currentUser.asObservable();
  }

  verifyLocalStorage() {
    if (this.jwtService.getJwtToken()) {
      this._currentUser.next(this.jwtService.getCurrentUser());
      this._isAuthenticated.next(true);
      return;
    }
    this.purgeAuth();
  }

  setAuth(auth: Auth) {
    this.jwtService.storeTokens(auth);
    this.jwtService.storeCurrentUser(auth);
    this._currentUser.next(this.jwtService.getCurrentUser());
    this._isAuthenticated.next(true);
  }

  purgeAuth() {
    this.jwtService.removeTokens();
    this.jwtService.removeCurrentUser();
    this.lsService.remove('rol');

    this._currentUser.next(null);
    this._isAuthenticated.next(false);
    this.router.navigate(['login']);
  }

  updateUser(user: CurrentUser) {
    this._currentUser.next(user);
  }

  login(loginData): Observable<any> {
    return this.http.post(`${this.REQUEST_ROUTE}/login`, loginData).pipe(
      map((data: Auth) => {
        this.setAuth(data);
        this.rolService.setRolAndAccesos(data.idRol);
        
      })
    );
  }

  register(registerData): Observable<any> {
    return this.http.post(`${this.REQUEST_ROUTE}`, registerData).pipe(
      map((data: Auth) => {
        this.setAuth(data);
        this.router.navigate(['']);
        this.util.hideLoader();
      })
    );
  }

  changePass(changePassData): Observable<any> {
    return this.http.post(`${this.REQUEST_ROUTE}/changePass`, changePassData);
  }

  logout() {
    this.purgeAuth();
    // return this.http.post<any>('/logout', { refreshToken: this.jwtService.getRefreshToken() }).pipe(
    //   map(() => {
    //     this.jwtService.removeTokens();
    //   })
    // );
  }

  refreshToken() {
    const payload = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.jwtService.getRefreshToken().toString());

    this.jwtService.removeTokens();

    return this.http.post('/oauth/token', payload).pipe(
      tap(
        (tokens: any) => {
          this.jwtService.storeJwtToken(tokens.jwt);
        },
        () => {
          this.logout();
        }
      )
    );
  }
}
