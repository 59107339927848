<div class="app-body">
    <ngx-spinner></ngx-spinner>
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form [formGroup]="loginForm" (ngSubmit)="submitForm()">
                                    <div style="text-align: center;">
                                        <img *ngIf="logoString == ''" src="assets/img/logo.png" style="height: 200px; margin-bottom: 50px;" />
                                        <img *ngIf="logoString != ''" src="{{logoString}}" style="height: 200px; margin-bottom: 50px;" />
                                    </div>
                                    <!-- <p class="text-muted">Inicia Sesión</p> -->
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-briefcase"></i></span>
                                        </div>
                                        <input (blur)="getLogoEmpresa($event)" [attr.maxlength]="11" type="text" class="form-control" placeholder="RUC"
                                            formControlName="ruc" autocomplete="ruc" required>
                                        <div class="invalid-feedback-login"
                                            *ngIf="formControls.ruc.errors && formControls.ruc.dirty">
                                            <div *ngIf="formControls.ruc.errors.required">Ingrese un RUC.
                                            </div>
                                            <div
                                                *ngIf="formControls.ruc.errors.maxlength || formControls.ruc.errors.minlength">
                                                Ingrese un RUC válido.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-user"></i></span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Email o DNI"
                                            formControlName="username" autocomplete="username" required>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" placeholder="Password"
                                            formControlName="password" autocomplete="current-password" required>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="submit" class="btn btn-primary px-4">Login</button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button type="button" class="btn btn-link px-0">Olvidaste tu
                                                contraseña?</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                            <div class="card-body text-center">
                                <div>
                                    <h2>Registro</h2>
                                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua.</p> 
                                    <button type="button" (click)="goToRegister()" class="btn btn-primary active mt-3">Registrate Ahora!</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>