import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/utils/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private util: CommonService,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit() {
    this.initForm();
    this.util.hideLoader();
  }

  initForm() {
    this.registerForm = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: ['', Validators.required],
      telefono: ['', Validators.required],
      correoElectronico: ['', Validators.required],
      password: ['', Validators.required],
      repPassword: ['', Validators.required]
    });
  }

  submitForm() {
    if (this.validateForm(this.registerForm) &&
      (this.registerForm.get('password').value === this.registerForm.get('repPassword').value)) {
      this.util.showLoader();
      let registerData = this.registerForm.getRawValue();
      delete registerData.repPassword;
      this.authService
        .register(
          registerData
        )
        .pipe(take(1))
        .subscribe(ok => { }, error => {
          if (error) {
            alert('Error en el registro.');
            this.util.hideLoader();
          }
        });
    }
    else {
      alert('Error en el formulario.')
    }
  }

  validateForm(formGroup: FormGroup): boolean {
    Object.values(formGroup.controls).forEach((control: FormControl | FormGroup) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.updateValueAndValidity();
      } else {
        this.validateForm(control);
      }
    });
    return formGroup.valid;
  }

  searchReniec() {
    const dni: string = this.registerForm.controls['dni'].value;
    if (dni.length === 8) {
      this.util.showLoader();
      this.usuarioService.info(dni).subscribe(res => {
        if (res.nombres !== '' && res.apellidos !== '') {
          this.registerForm.patchValue(
            {
              apellidos: this.titleCase(res.apellidos),
              nombres: this.titleCase(res.nombres)
            }
          );
        }
        else {
          alert('No se encontraron datos para este DNI');
        }
        this.util.hideLoader();
      },
        error => {
          alert('Ocurrió un error, intente de nuevo');
          console.log(error);
          this.util.hideLoader();
        });
    }
    else if (dni.length < 8) {
      alert('Introduzca un DNI válido.');
    }
    else {
      alert('Sólo disponible para DNIs');
    }
  }

  titleCase(string: string) {
    let splitStr = string.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
}
