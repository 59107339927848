import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Empresa } from '../models/empresa.model';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private route = environment.apiUrl + "/empresas";

  constructor(private http: HttpClient) { }

  getAll(): Observable<Empresa[]> {
    return this.http.get<any>(this.route);
  }

  getCurrent(): Observable<Empresa> {
    return this.http.get<any>(this.route + '/current');
  }

  getLogo(ruc:string): Observable<Empresa> {
    return this.http.get<any>(this.route + '/logo/'+ruc);
  }

  save(empresa: Empresa): Observable<Empresa> {
    return this.http.post<any>(this.route, empresa);
  }

  getById(id: number): Observable<Empresa> {
    return this.http.get<Empresa>(this.route + `/${id}`);
  }

  update(empresa: Empresa, id: number): Observable<Empresa> {
    return this.http.patch<any>(this.route + `/${id}`, empresa);
  }

  delete(id: number): Observable<Empresa> {
    return this.http.patch<any>(this.route + `/${id}`,{estado:'DES'});
  }

}
