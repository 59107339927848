import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmpresaService {
    constructor(http) {
        this.http = http;
        this.route = environment.apiUrl + "/empresas";
    }
    getAll() {
        return this.http.get(this.route);
    }
    getCurrent() {
        return this.http.get(this.route + '/current');
    }
    getLogo(ruc) {
        return this.http.get(this.route + '/logo/' + ruc);
    }
    save(empresa) {
        return this.http.post(this.route, empresa);
    }
    getById(id) {
        return this.http.get(this.route + `/${id}`);
    }
    update(empresa, id) {
        return this.http.patch(this.route + `/${id}`, empresa);
    }
    delete(id) {
        return this.http.patch(this.route + `/${id}`, { estado: 'DES' });
    }
}
EmpresaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmpresaService_Factory() { return new EmpresaService(i0.ɵɵinject(i1.HttpClient)); }, token: EmpresaService, providedIn: "root" });
