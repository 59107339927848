import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';

const AUTH_HEADER: string = 'Authorization';
const CONTENT_TYPE: string = 'Content-type';
const BEARER: string = 'Bearer';
const MIME_JSON: string = 'application/json';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const requestUrl = this.addUrl(request);

    const requestContentType = this.addContentType(request);

    const token = this.jwtService.getJwtToken();

    return next.handle(token ? this.addToken(requestContentType, token) : requestContentType);
  }

  // addUrl(request: HttpRequest<any>) {
  //   return request.clone({
  //     url: environment.apiUrl + '/api/' + request.url
  //   });
  // }

  addContentType(request: HttpRequest<any>) {
    if (!request.headers.has(CONTENT_TYPE)) {
      return request.clone({
        headers: request.headers.set(CONTENT_TYPE, MIME_JSON)
      });
    }
    return request;
  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(AUTH_HEADER, `${BEARER} ${token}`)
    });
  }
}
