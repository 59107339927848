import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpecialHtmlComponent } from './special-html/special-html.component';
import { SpecialHtmlService } from './special-html/special.html.service';



@NgModule({
  declarations: [FormComponent, SpecialHtmlComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    FormComponent,
    SpecialHtmlComponent
  ],
  providers: [
    SpecialHtmlService
  ]
})
export class FormModule { }
