import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseComponent } from './browse.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [BrowseComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [BrowseComponent]
})
export class BrowseModule { }
