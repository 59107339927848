import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Rol } from '../models/rol.model';
import { take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { CONSTANTES } from '../utils/constants';
import { Location } from '@angular/common';
import { CommonService } from '../utils/common.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RolService {
  private route = environment.apiUrl + "/rols";

  private _rol = new BehaviorSubject<Rol>(null);

  constructor(
    private http: HttpClient,
    private lsService: LocalStorageService,
    private location: Location,
    private router: Router,
    private util: CommonService
  ) { }

  get accesos() {
    return this._rol.asObservable();
  }

  setAccesos(rol: Rol) {
    this._rol.next(rol);
  }

  getAll(): Observable<Rol[]> {
    return this.http.get<any>(this.route);
  }

  info(numeroDocumento: string): Observable<any> {
    return this.http.get<any>(this.route + '/info/' + numeroDocumento);
  }

  save(rol: Rol): Observable<Rol> {
    return this.http.post<any>(this.route, rol);
  }

  getById(id: number): Observable<Rol> {
    return this.http.get<Rol>(this.route + `/${id}`);
  }

  getDetalleById(id: number): Observable<Rol> {
    return this.http.get<Rol>(this.route + `/detalle/${id}`);
  }

  getRolAndAccesosById(id: number): Observable<Rol> {
    return this.http.get<Rol>(this.route + `/${id}`);
  }

  update(rol: Rol, id: number): Observable<Rol> {
    return this.http.patch<any>(this.route + `/${id}`, rol);
  }

  delete(id: number): Observable<Rol> {
    return this.http.delete<Rol>(this.route + `/${id}`);
  }

  setRolAndAccesos(id: number) {
    this.getRolAndAccesosById(id).pipe(take(1)).subscribe((rol) => {
      //this.setAccesos(rol);
      this.lsService.remove('rol');
      this.lsService.set('rol', rol);
      this.router.navigate(['']);
      this.util.hideLoader();
    });
  }

  getRolFromLS(): Rol {
    return this.lsService.get('rol');
  }

  getRol(module: string, error: Function) {
    let rol = this.lsService.get('rol');
    if (rol) {
      let rolAcceso = rol.rolAccesos.find(acceso => acceso.modulo === module);
      if (rolAcceso) {
        return +rolAcceso.nivel;
      } else {
        alert('Faltan roles de acceso.')
        error();
      }
    } else {
      alert('Su sesión ha caducado, vuela a iniciarla.')
      error();
    }
  }

  checkAccessLevel(currentAccessLevel, requiredAccessLevel) {
    if (currentAccessLevel < requiredAccessLevel) {
      alert(CONSTANTES.ERRORES.FORBIDDEN.message);
      this.location.back();
    }
  }

}
