import * as i0 from "@angular/core";
import * as i1 from "ngx-spinner";
export class CommonService {
    constructor(spinner) {
        this.spinner = spinner;
    }
    hideLoader() {
        setTimeout(() => {
            this.spinner.hide();
        }, 500);
    }
    showLoader() {
        this.spinner.show();
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.NgxSpinnerService)); }, token: CommonService, providedIn: "root" });
