import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/utils/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { showAlert } from 'src/app/utils/common';
import { EmpresaService } from 'src/app/services/empresa.service';
import { RolService } from 'src/app/services/rol.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  logoString: string = '';

  constructor(
    private fb: FormBuilder,
    private rolService: RolService,
    private authService: AuthService,
    private util: CommonService,
    private activedRoute: ActivatedRoute,
    private route: Router,
    private empresaService: EmpresaService
  ) { }

  ngOnInit() {
    this.initForm();
    this.util.hideLoader();
  }

  initForm() {
    this.loginForm = this.fb.group({
      password: ['', Validators.required],
      username: ['', Validators.required],
      ruc: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])]
    });
  }

  getLogoEmpresa($event) {
    let ruc: string = $event.target.value;
    if (ruc.length == 11) {
      this.empresaService.getLogo(ruc).subscribe((empresa) => {
        let logo = Buffer.from(empresa.logotipo);
        let src = 'data:image/png;base64,' + btoa(
          logo.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        this.logoString = src;
      });
    }
  }

  submitForm() {
    if (this.validateForm(this.loginForm)) {
      this.util.showLoader();
      this.authService
        .login(
          this.loginForm.getRawValue()
        )
        .pipe(take(1))
        .subscribe(ok => {}, error => {
          if (error.status === 404 || error.status === 401) {
            alert('Usuario o contraseña incorrectos');
            this.util.hideLoader();
          } else if (error.status === 403) {
            alert('Su empresa esta desactivada, por favor contacte a la administración.');
            this.util.hideLoader();
          } else {
            alert('Ocurrió un error inesperado. Intente de nuevo más tarde.');
            this.util.hideLoader();
          }
        });
    }
  }

  get formControls() {
    return this.loginForm.controls;
  }

  validateForm(formGroup: FormGroup): boolean {
    Object.values(formGroup.controls).forEach((control: FormControl | FormGroup) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.updateValueAndValidity();
      } else {
        this.validateForm(control);
      }
    });
    return formGroup.valid;
  }

  goToRegister() {
    this.route.navigate(['register'], { relativeTo: this.activedRoute.parent });
  }
}
