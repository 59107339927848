import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class SpecialHtmlService {

    dniInput = '';

    reniec;

    @Output() dniChange: EventEmitter<string> = new EventEmitter();

    @Output() reniecResult: EventEmitter<any> = new EventEmitter();

    setDniInput(dniInput: string) {
        this.dniInput = dniInput;
        this.dniChange.emit(this.dniInput);
    }

    setReniecResult(reniec: any) {
        this.reniec = reniec;
        this.reniecResult.emit(this.reniec);
    }

}