import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/current-user.model';
import { Auth } from '../models/auth.model';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class JwtService {
  private readonly CURRENT_USER = 'CURRENT_USER';

  private readonly JWT_TOKEN = 'JWT_TOKEN';

  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  constructor(private lsService: LocalStorageService) { }

  getJwtToken(): string {
    return this.lsService.get(this.JWT_TOKEN);
    //return ls.get<string>(this.JWT_TOKEN);
  }

  getRefreshToken(): string {
    return this.lsService.get(this.REFRESH_TOKEN);
    //return ls.get<string>(this.REFRESH_TOKEN);
  }

  getCurrentUser(): CurrentUser {
    return this.lsService.get(this.CURRENT_USER);
    //return ls.get<CurrentUser>(this.CURRENT_USER);
  }

  storeCurrentUser({ nombre, dni, correoElectronico, id, idRol }: Auth) {
    // ls.set<CurrentUser>(this.CURRENT_USER, {
    //   nombre,
    //   dni,
    //   correoElectronico,
    //   id,
    //   idRol
    // });
    this.lsService.set(this.CURRENT_USER, {
      nombre,
      dni,
      correoElectronico,
      id,
      idRol
    });
  }

  storeTokens({ jwtToken, refreshToken }: Auth) {
    // ls.set<string>(this.JWT_TOKEN, jwtToken);
    // ls.set<string>(this.REFRESH_TOKEN, refreshToken);
    this.lsService.set(this.JWT_TOKEN, jwtToken);
    this.lsService.set(this.REFRESH_TOKEN, refreshToken);
  }

  storeJwtToken(jwtToken: string) {
    this.lsService.set(this.JWT_TOKEN, jwtToken);
    //ls.set<string>(this.JWT_TOKEN, jwtToken);
  }

  removeCurrentUser() {
    this.lsService.remove(this.CURRENT_USER);
    //ls.remove(this.CURRENT_USER);
  }

  removeTokens() {
    this.lsService.remove(this.JWT_TOKEN);
    this.lsService.remove(this.REFRESH_TOKEN);
    // ls.remove(this.JWT_TOKEN);
    // ls.remove(this.REFRESH_TOKEN);
  }
}
