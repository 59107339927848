import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CONSTANTES } from '../utils/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../utils/common.service";
export class RolService {
    constructor(http, lsService, location, router, util) {
        this.http = http;
        this.lsService = lsService;
        this.location = location;
        this.router = router;
        this.util = util;
        this.route = environment.apiUrl + "/rols";
        this._rol = new BehaviorSubject(null);
    }
    get accesos() {
        return this._rol.asObservable();
    }
    setAccesos(rol) {
        this._rol.next(rol);
    }
    getAll() {
        return this.http.get(this.route);
    }
    info(numeroDocumento) {
        return this.http.get(this.route + '/info/' + numeroDocumento);
    }
    save(rol) {
        return this.http.post(this.route, rol);
    }
    getById(id) {
        return this.http.get(this.route + `/${id}`);
    }
    getDetalleById(id) {
        return this.http.get(this.route + `/detalle/${id}`);
    }
    getRolAndAccesosById(id) {
        return this.http.get(this.route + `/${id}`);
    }
    update(rol, id) {
        return this.http.patch(this.route + `/${id}`, rol);
    }
    delete(id) {
        return this.http.delete(this.route + `/${id}`);
    }
    setRolAndAccesos(id) {
        this.getRolAndAccesosById(id).pipe(take(1)).subscribe((rol) => {
            //this.setAccesos(rol);
            this.lsService.remove('rol');
            this.lsService.set('rol', rol);
            this.router.navigate(['']);
            this.util.hideLoader();
        });
    }
    getRolFromLS() {
        return this.lsService.get('rol');
    }
    getRol(module, error) {
        let rol = this.lsService.get('rol');
        if (rol) {
            let rolAcceso = rol.rolAccesos.find(acceso => acceso.modulo === module);
            if (rolAcceso) {
                return +rolAcceso.nivel;
            }
            else {
                alert('Faltan roles de acceso.');
                error();
            }
        }
        else {
            alert('Su sesión ha caducado, vuela a iniciarla.');
            error();
        }
    }
    checkAccessLevel(currentAccessLevel, requiredAccessLevel) {
        if (currentAccessLevel < requiredAccessLevel) {
            alert(CONSTANTES.ERRORES.FORBIDDEN.message);
            this.location.back();
        }
    }
}
RolService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolService_Factory() { return new RolService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.CommonService)); }, token: RolService, providedIn: "root" });
