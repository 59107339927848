import { environment } from 'src/environments/environment';
import * as uuidv4 from 'uuid/v4';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UsuarioService {
    constructor(http) {
        this.http = http;
        this.route = environment.apiUrl + "/usuarios";
    }
    getAll() {
        return this.http.get(this.route);
    }
    info(numeroDocumento) {
        return this.http.get(this.route + '/info/' + numeroDocumento);
    }
    save(usuario) {
        usuario.codigo = uuidv4();
        usuario.estado = 'ACT';
        usuario.idRol = +usuario.idRol;
        return this.http.post(this.route, usuario);
    }
    getById(id) {
        return this.http.get(this.route + `/${id}`);
    }
    update(usuario, id) {
        usuario.idRol = +usuario.idRol;
        return this.http.patch(this.route + `/${id}`, usuario);
    }
    delete(id) {
        return this.http.patch(this.route + `/${id}`, { estado: 'DES' });
    }
}
UsuarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsuarioService_Factory() { return new UsuarioService(i0.ɵɵinject(i1.HttpClient)); }, token: UsuarioService, providedIn: "root" });
