import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../models/usuario.model';
import { Observable } from 'rxjs';
import * as uuidv4 from 'uuid/v4';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private route = environment.apiUrl + "/usuarios";

  constructor(private http: HttpClient) { }

  getAll(): Observable<Usuario[]> {
    return this.http.get<any>(this.route);
  }

  info(numeroDocumento: string): Observable<any> {
    return this.http.get<any>(this.route+'/info/'+numeroDocumento);
  }

  save(usuario: Usuario): Observable<Usuario> {
    usuario.codigo = uuidv4();
    usuario.estado = 'ACT';
    usuario.idRol = +usuario.idRol;
    return this.http.post<any>(this.route, usuario);
  }

  getById(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(this.route + `/${id}`);
  }

  update(usuario: Usuario, id: number): Observable<Usuario> {
    usuario.idRol = +usuario.idRol;
    return this.http.patch<any>(this.route + `/${id}`, usuario);
  }

  delete(id: number): Observable<Usuario> {
    return this.http.patch<any>(this.route + `/${id}`,{estado:'DES'});
  }

}
