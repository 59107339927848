import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
export class LocalStorageService {
    constructor(storageService) {
        this.storageService = storageService;
        this.changes$ = new Subject();
        this.localStorage = window.localStorage;
    }
    get(key) {
        if (this.isLocalStorageSupported) {
            //return JSON.parse(this.localStorage.getItem(key));
            return this.storageService.secureStorage.getItem(key);
        }
        return null;
    }
    set(key, value) {
        if (this.isLocalStorageSupported) {
            this.storageService.secureStorage.setItem(key, value);
            // this.localStorage.setItem(key, JSON.stringify(value));
            this.changes$.next({
                type: 'set',
                key,
                value
            });
            return true;
        }
        return false;
    }
    remove(key) {
        if (this.isLocalStorageSupported) {
            this.storageService.secureStorage.removeItem(key);
            this.changes$.next({
                type: 'remove',
                key
            });
            return true;
        }
        return false;
    }
    get isLocalStorageSupported() {
        return !!this.localStorage;
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(i0.ɵɵinject(i1.StorageService)); }, token: LocalStorageService, providedIn: "root" });
