import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(
        private spinner: NgxSpinnerService
    ) { }

    hideLoader() {
        setTimeout(() => {
            this.spinner.hide();
        }, 500);
    }

    showLoader() {
        this.spinner.show();
    }
}
