import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './components/views/error/404.component';
import { P500Component } from './components/views/error/500.component';
import { AuthGuard } from './services/auth.guard';
import { RegisterComponent } from './components/register/register.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  }, 
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Registro'
    }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'Inicio'
    },
    children: [
      {
        path: 'inventario',
        loadChildren: () => import('./components/views/inventario/inventario.module').then(m => m.InventarioModule)
      },
      {
        path: 'administracion',
        loadChildren: () => import('./components/views/administracion/administracion.module').then(m => m.AdministracionModule)
      },
      {
        path: 'empresa',
        loadChildren: () => import('./components/views/empresa/empresa.module').then(m => m.EmpresaModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./components/views/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
