export const CONSTANTES = {
    EMPRESA: {
        ACTIVA: 'ACT',
        INACTIVA: 'INA'
    },
    TIPOS_DOCUMENTO: [
        { id: 1, value: "SIN RUC - No domicialiado" },
        { id: 2, value: "DNI - Doc. Nacional de Identidad" },
        { id: 3, value: "CE - Carnet de extranjeria" },
        { id: 4, value: "RUC - Registro Único de Contribuyentes" },
        { id: 5, value: "PP - Pasaporte" }
    ],
    MODULOS: [
        { modulo: "EMPRESA", label: "Empresa", nivel: 0 },
        { modulo: "PRODUCTO", label: "Productos", nivel: 0 },
        { modulo: "CATEGORIA", label: "Categorias", nivel: 0 },
        { modulo: "MOVIMIENTO", label: "Movimientos", nivel: 0 },
        { modulo: "TIPO_MOVIMIENTO", label: "Tipos Movimientos", nivel: 0 },
        { modulo: "DOCUMENTO", label: "Documentos", nivel: 0 },
        { modulo: "LOCALIZACION", label: "Localización", nivel: 0 },
        { modulo: "ESTADO_TIPO_DOCUMENTO", label: "Estado documentos", nivel: 0 },
        { modulo: "TIPO_DOCUMENTO", label: "Tipo documentos", nivel: 0 },
        { modulo: "CONTACTO", label: "Contactos", nivel: 0 },
        { modulo: "USUARIO", label: "Usuarios", nivel: 0 },
        { modulo: "ROL", label: "Roles", nivel: 0 }        
    ],
    NIVELES_ACCESO_LIST: [
        { nivel: 0, descripcion: 'Sin Acceso' },
        { nivel: 10, descripcion: 'Lectura' },
        { nivel: 20, descripcion: 'Escritura' },
        { nivel: 30, descripcion: 'Exportación' },
        { nivel: 40, descripcion: 'Importación' }
    ],
    NIVELES_ACCESO: {
        SIN_ACCESO: 0,
        LECTURA: 10,
        ESCRITURA: 20,
        EXPORTACION: 30,
        IMPORTACION: 40
    },
    NIVELES_VISIBILIDAD: [
        { nivel: 0, descripcion: 'No visible' },
        { nivel: 1, descripcion: 'Visible' }
    ],
    ERRORES: {
        FORBIDDEN: { name: 'Forbidden', message: 'No tiene permisos para realizar esta acción', code: 403 }
    }
}